import axios from "@/axios.js";

export default {
  async fetchReasons({ commit }) {
    try {
      const { data } = await axios.get(`/why-choose-us`);
      commit("setReasons", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchReason({ commit }, params) {
    try {
      const { data } = await axios.get(`/why-choose-us/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeReason({ commit }, params) {
    try {
      const { data } = await axios.post(`/why-choose-us`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateReason({ commit }, params) {
    try {
      const { data } = await axios.post(`/why-choose-us/${params.id}`, params.body);
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeReason({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/why-choose-us/${params}`);
      dispatch("fetchReasons");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};

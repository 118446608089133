<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Why Choose Us</h4>
    </vs-row>

    <vs-row v-if="!loading" vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Title (*)"
              v-model="payload.title"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("title")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <p class="mb-0" style="font-size: 12px; color: #000000b3">
              Description (*)
            </p>
            <vs-textarea class="w-full" v-model="payload.description" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("description")
            }}</span>
          </vs-col>

          <vs-col vs-w="12">
            <p class="mb-0 text-sm" style="color: #000000b3">Icon</p>
            <div class="flex items-start flex-col sm:flex-row">
              <img
                :src="url ? url : defaultUrl"
                class="mr-4"
                width="100"
                height="100"
              />
              <div>
                <input
                  type="file"
                  @change="addImage"
                  class="hidden"
                  ref="update_logo_input"
                  accept="image/*"
                />
                <vs-button
                  @click="$refs.update_logo_input.click()"
                  class="mr-4 mb-2"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
                <vs-button
                  @click="removeImage()"
                  type="border"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-trash"
                ></vs-button>
              </div>
            </div>
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("image")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import reasonStore from "@/store/why-us";

export default {
  name: "WhyChooseUsEdit",

  metaInfo: {
    title: "Edit Why Choose Us",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {},

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
    brands() {
      return this.$store.state.brandManagement.brands;
    },
  },

  data: () => ({
    loading: true,
    payload: {
      title: "",
      description: "",
      image: null,
    },
    defaultUrl: "https://via.placeholder.com/40",
    url: null,
  }),

  methods: {
    async getData() {
      this.$vs.loading();

      const response = await this.$store.dispatch(
        "reason/fetchReason",
        this.decryptedID
      );
      this.payload.title = response.message.title;
      this.payload.description = response.message.description;
      this.url = response.message.banner;
      this.loading = false;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("title", this.payload.title);
      formData.set("description", this.payload.description);
      formData.set("banner", this.payload.image);
      formData.append("_method", "PUT");
      const response = await this.$store.dispatch("reason/updateReason", {
        id: this.decryptedID,
        body: formData,
      });
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/why-choose-us");
    },
    addImage(e) {
      const file = e.target.files[0];
      console.log(file);
      this.url = URL.createObjectURL(file);
      this.payload.image = file;
    },
    removeImage() {
      this.url = null;
      this.payload.image = null;
    },
  },

  created() {
    if (!reasonStore.isRegistered) {
      this.$store.registerModule("reason", reasonStore);
      reasonStore.isRegistered = true;
    }
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style></style>
